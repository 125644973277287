import React, { useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { ProductIcon } from "../../theme/svg/ProductIcon";
import { ServiceIcon } from "../../theme/svg/ServiceIcon";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import {
  RfxByCodeRfxByCode,
  PurchaseRequestItemTypeEnum,
  SourcingRequestStatusEnum,
  CurrencyEnum,
} from "../../schema";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { formatDatetime } from "../../services/formatDatetime";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { SourcingEventTemplate } from "../RfqEventForm/RfqEventForm";
import { Tooltip } from "../Tooltip/Tooltip";
import { WinnerIcon } from "../../theme/svg/WinnerIcon";
import styles from "./ComparisonTable.module.scss";
import { addSourcingRequestWinners } from "./addSourcingRequestWinners";

export interface ComparisonTableProps {
  className?: string;
  rfx?: RfxByCodeRfxByCode;
  baseCurrency?: CurrencyEnum;
  showItemSelect?: boolean;
  onItemSelect?: (itemIds: string[]) => void;
}

// eslint-disable-next-line react/display-name
export const ComparisonTable = React.forwardRef(
  (props: ComparisonTableProps, ref) => {
    const {
      className,
      rfx,
      baseCurrency = CurrencyEnum.EUR,
      showItemSelect,
      onItemSelect,
    } = props;

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleItemSelect = (selectedItemId: string) => {
      let existingItems = selectedItems;

      if (selectedItems.includes(selectedItemId)) {
        existingItems = selectedItems.filter(
          (itemId) => itemId !== selectedItemId,
        );

        setSelectedItems(existingItems);
      } else {
        existingItems = [...selectedItems, selectedItemId];

        setSelectedItems(existingItems);
      }

      // handle callback
      if (typeof onItemSelect === "function") {
        onItemSelect(existingItems);
      }
    };

    const handleClearSelectedItems = () => {
      setSelectedItems([]);
    };

    // assign comparison table ref
    useImperativeHandle(ref, () => ({
      handleClearSelectedItems,
    }));

    const sourcingEvent = rfx?.sourcingEvent;

    const sourcingEventQuestionnaire =
      sourcingEvent?.questionnaire as SourcingEventTemplate;

    const sourcingRequests = sourcingEvent?.sourcingRequests;

    const completedSourcingRequests =
      sourcingRequests?.filter(
        (req) => req.status === SourcingRequestStatusEnum.COMPLETE,
      ) ?? [];

    const completedSourcingRequestsWithWinners = addSourcingRequestWinners(
      completedSourcingRequests,
    );

    const combinedQuestionsData =
      sourcingEventQuestionnaire.generalQuestions.map((genQ, genQIdx) => ({
        question: genQ.question,
        answers: completedSourcingRequestsWithWinners.map((offer) =>
          (offer.quotation as SourcingEventTemplate).generalQuestions[genQIdx]
            ? (offer.quotation as SourcingEventTemplate).generalQuestions[
                genQIdx
              ].answer
            : "",
        ),
      }));

    const combinedItemsData = sourcingEventQuestionnaire.items.map((item) => ({
      id: item.id,
      itemData: item.itemData,
      quantities: item.priceInfo.map((priceInfo) => priceInfo.quantity),
      quotations: completedSourcingRequestsWithWinners.map((offer) => {
        const offerItem = (offer.quotation as SourcingEventTemplate).items.find(
          (offerItem) => offerItem.id === item.id,
        );

        return {
          priceInfo: offerItem?.priceInfo ? offerItem.priceInfo : [],
          itemAnswers: offerItem?.itemQuestions ? offerItem.itemQuestions : [],
          supplierId: offer.supplierId,
        };
      }),
      winningSupplierId: item.winningSupplierId ?? null,
      hasMovedToPo: item.hasMovedToPo ?? false,
      itemQuestions: item.itemQuestions,
    }));

    // display when there are no completed sourcing requests
    if (completedSourcingRequestsWithWinners.length === 0) {
      return (
        <div className={styles["offer-data-missing"]}>
          Your received offers will be displayed here
        </div>
      );
    }

    return (
      <div className={classNames(styles["wrap"], className)}>
        <BasicTable className={styles.table}>
          {/* render table header */}
          <thead>
            <tr>
              <Cell header />
              {completedSourcingRequestsWithWinners.map((offer) => (
                <Cell center key={offer.id}>
                  <p className={styles.supplier}>{offer.supplier?.name}</p>
                  <p>{formatTimeAgo(offer.updatedDate)}</p>
                  <p className={styles.date}>
                    {formatDatetime(offer.updatedDate)}
                  </p>
                </Cell>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* render questions and answers */}
            {combinedQuestionsData?.map((genQuestion, genQuestionIdx) => (
              <tr key={genQuestionIdx}>
                <Cell header className={styles["cell"]}>
                  <div className={styles["cell-contents-label-bold"]}>
                    {genQuestion.question}
                  </div>
                </Cell>
                {genQuestion?.answers?.map((answer, answerIndex) => (
                  <Cell center key={answerIndex} className={styles["cell"]}>
                    <div className={classNames(styles["question-value"])}>
                      {answer}
                    </div>
                  </Cell>
                ))}
              </tr>
            ))}

            {/* render items and quotes */}
            {combinedItemsData?.map((item) => (
              <tr key={item.id}>
                <Cell header className={classNames(styles["cell"])}>
                  <div
                    className={classNames({
                      [styles["cell-with-select"]]: showItemSelect,
                    })}
                  >
                    {showItemSelect && !item.hasMovedToPo && (
                      <div className={styles["cell-select"]}>
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(item.id)}
                          onChange={() => handleItemSelect(item.id)}
                        />
                      </div>
                    )}
                    <div className={styles["cell-contents"]}>
                      <div className={styles["item"]}>
                        {item.itemData.type ===
                        PurchaseRequestItemTypeEnum.PRODUCT ? (
                          <ProductIcon className={styles.icon} />
                        ) : (
                          <ServiceIcon className={styles.icon} />
                        )}
                        <span>{item.itemData.name}</span>
                      </div>

                      {/* item quantities */}
                      {item.quantities.map((q, idx) => (
                        <div
                          key={idx}
                          className={styles["cell-contents-label-right"]}
                        >
                          {q} {item.itemData.unit}
                        </div>
                      ))}

                      {/* item questions */}
                      {item.itemQuestions.length > 0 && (
                        <div className={styles["item-questions-list"]}>
                          {item.itemQuestions.map((itemQuestion, idx) => (
                            <div key={idx} className={styles["item-question"]}>
                              <div
                                className={styles["item-question__title"]}
                              >{`Question ${idx + 1}`}</div>
                              <Tooltip
                                reference={
                                  <div
                                    key={idx}
                                    className={styles["item-question__text"]}
                                  >
                                    {itemQuestion.question}
                                  </div>
                                }
                              >
                                {itemQuestion.question}
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Cell>

                {/* quotations */}
                {item.quotations?.map((quote, quoteIdx) => {
                  const shouldExclude =
                    quote.priceInfo.length === 0 &&
                    quote.itemAnswers.length === 0;

                  const isCherryPickWinner =
                    item.winningSupplierId === quote.supplierId ?? false;

                  return (
                    <Cell
                      center
                      key={quoteIdx}
                      className={classNames(styles.unit_price_cell, {
                        [styles["isExcluded"]]: shouldExclude,
                      })}
                    >
                      <div className={styles["cell-contents"]}>
                        {quote.priceInfo.map((pi, piIdx) => {
                          const totalPriceForQuantity =
                            pi.price && pi.quantity
                              ? (Number(pi.price) * Number(pi.quantity))
                                  .toLocaleString()
                                  .replace(/,/g, " ")
                              : undefined;

                          return (
                            <div
                              key={piIdx}
                              className={classNames(
                                styles["cell-content-wrap"],
                                {
                                  [styles[
                                    "cell-content-wrap__cherry-pick-winner"
                                  ]]: isCherryPickWinner,
                                },
                              )}
                            >
                              {isCherryPickWinner && (
                                <WinnerIcon fill={styles.colorSuccess} />
                              )}
                              <div
                                className={classNames(
                                  styles["cell-contents-value"],
                                  styles["align-right"],
                                )}
                              >
                                <div
                                  className={classNames({
                                    [styles["winner-item"]]: pi.isRowWinner,
                                  })}
                                >
                                  <p>
                                    {pi.price
                                      ? `${Number(pi.price)} ${baseCurrency}`
                                      : "..."}
                                  </p>
                                  <p>
                                    {totalPriceForQuantity
                                      ? `(${totalPriceForQuantity} ${baseCurrency})`
                                      : "..."}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* item questions */}
                      {quote.itemAnswers.length > 0 && (
                        <div className={styles["item-questions-list"]}>
                          {quote.itemAnswers.map((itemQuestion, idx) => (
                            <div key={idx} className={styles["item-question"]}>
                              <div
                                className={styles["item-question__title"]}
                              >{`Answer ${idx + 1}`}</div>
                              {itemQuestion.answer &&
                              itemQuestion.answer.length > 0 ? (
                                <Tooltip
                                  reference={
                                    <div
                                      key={idx}
                                      className={styles["item-question__text"]}
                                    >
                                      {itemQuestion.answer}
                                    </div>
                                  }
                                >
                                  {itemQuestion.answer}
                                </Tooltip>
                              ) : (
                                <div
                                  key={idx}
                                  className={styles["item-question__text"]}
                                >
                                  ...
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Cell>
                  );
                })}
              </tr>
            ))}

            {/* render total prices */}
            <tr>
              <Cell header className={styles["cell"]}>
                <div className={styles["cell-contents-label-bold"]}>
                  TOTAL OFFER
                </div>
              </Cell>

              {completedSourcingRequestsWithWinners?.map(
                (sourcingReq, index) => (
                  <Cell center key={index} className={styles.unit_price_cell}>
                    <div className={styles["cell-contents"]}>
                      <div
                        className={classNames(
                          styles["cell-contents-value"],
                          styles["align-right"],
                          {
                            [styles["winner-item"]]:
                              sourcingReq.isTotalPriceWinner,
                          },
                        )}
                      >
                        {sourcingReq.totalPrice
                          ? `
                        ${Number(sourcingReq.totalPrice)
                          .toLocaleString()
                          .replace(/,/g, " ")} ${baseCurrency}`
                          : "..."}
                      </div>
                    </div>
                  </Cell>
                ),
              )}
            </tr>

            {/* render additional info */}
            <tr>
              <Cell header className={styles["cell"]}>
                <div className={styles["cell-contents-label-bold"]}>
                  Comments
                </div>
              </Cell>
              {completedSourcingRequests?.map((sourcingReq, index) => (
                <Cell center key={index} className={styles["cell"]}>
                  <div className={classNames(styles["question-value"])}>
                    {sourcingReq.comment}
                  </div>
                </Cell>
              ))}
            </tr>

            {/* render attachments */}
            <tr>
              <Cell header className={styles["cell"]}>
                <div className={styles["cell-contents-label-bold"]}>
                  Attachments
                </div>
              </Cell>
              {completedSourcingRequests?.map((sourcingReq, index) => (
                <Cell center key={index} className={styles["cell"]}>
                  {sourcingReq.attachments?.map((attachment) =>
                    attachment.url ? (
                      <div
                        key={attachment.url}
                        className={classNames(styles["question-value"])}
                      >
                        <a
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>{attachment.filename}</span>
                          <OpenExternalIcon
                            className={styles["open-external-icon"]}
                          />
                        </a>
                      </div>
                    ) : null,
                  )}
                </Cell>
              ))}
            </tr>
          </tbody>
        </BasicTable>
      </div>
    );
  },
);
