import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useFieldArray, useFormContext } from "react-hook-form";
import { isEmpty } from "ramda";
import { ProductIcon } from "../../theme/svg/ProductIcon";
import { ServiceIcon } from "../../theme/svg/ServiceIcon";
import { EditIcon } from "../../theme/svg/EditIcon";
import { LinkIcon } from "../../theme/svg/LinkIcon";
import { CalendarIcon } from "../../theme/svg/CalendarIcon";
import { UploadedAttachment } from "../UploadedAttachment/UploadedAttachment";
import {
  PurchaseRequestItemTypeEnum,
  CurrencyEnum,
  useRemovePurchaseRequestItem,
  ViewerOrganizations,
  RfxByCodeRfxByCode,
} from "../../schema";
import { formatDate } from "../../services/formatDate";
import { SourcingEventTemplate } from "../RfqEventForm/RfqEventForm";
import { DummyInputPlaceholder } from "../DummyInputPlaceholder/DummyInputPlaceholder";
import { AttachmentIcon } from "../../theme/svg/AttachmentIcon";
import { QuantityList } from "../QuantityList/QuantityList";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import { QuestionList } from "../QuestionList/QuestionList";
import { Button } from "../Button/Button";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import { SupplierSelectDropdown } from "../SupplierSelectWithDropdown/SupplierSelectDropdown";
import { SupplierIcon } from "../../theme/svg/SupplierIcon";
import styles from "./RfqItemQuestionnaire.module.scss";

export interface RfqItemQuestionnaireProps {
  className?: string;
  displayHeader?: boolean;
  organization: ViewerOrganizations;
  rfx: RfxByCodeRfxByCode;
  triggerSubmit?: () => Promise<void>;
  onSuppliersChange?: (
    uniqueSelectedSuppliers: UniqueSelectedSupplier[],
  ) => void;
}

export interface UniqueSelectedSupplier {
  supplierId: string;
  isSelected: boolean;
}

export type QuestionListRefType = {
  itemIndex: number;
  append: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined,
  ) => void;
};

const canDuplicate = (
  ref: React.MutableRefObject<QuestionListRefType | undefined>,
  itemIndex: number,
): ref is React.MutableRefObject<QuestionListRefType> => {
  return (
    ref.current !== undefined &&
    ref.current.append &&
    ref.current.itemIndex !== itemIndex
  );
};

export const RfqItemQuestionnaire: React.FC<RfqItemQuestionnaireProps> = (
  props,
) => {
  const {
    className,
    organization,
    rfx,
    displayHeader = false,
    triggerSubmit,
    onSuppliersChange,
  } = props;

  const baseCurrency = organization.baseCurrency ?? CurrencyEnum.EUR;

  // use read only context
  const isReadOnly = useReadOnlyContext();

  const { control, getValues, register, watch } = useFormContext();

  const { fields: items } = useFieldArray<
    Unbox<SourcingEventTemplate["items"]>
  >({
    control,
    name: "items",
  });

  const appendRef = useRef(items.map(() => useRef<QuestionListRefType>()));

  // set up watch for item related form changes
  watch("items");

  const [itemsState, setItemsState] = React.useState(items);
  const [selectedSuppliers, setSelectedSuppliers] = React.useState(
    getUniqueSelectedSuppliers(items),
  );

  const [deletingItem, setDeletingItem] = React.useState<{
    itemId: string;
  } | null>(null);

  function getUniqueSelectedSuppliers(
    items: Partial<Unbox<SourcingEventTemplate["items"]>>[],
  ): UniqueSelectedSupplier[] {
    const uniqueSuppliers: UniqueSelectedSupplier[] = [];

    for (const item of items) {
      if (!item.excludedItemSuppliers) {
        break;
      }

      for (const supplier of item.excludedItemSuppliers) {
        if (
          supplier.isSelected &&
          !uniqueSuppliers.find(
            (uniqueSupplier) =>
              uniqueSupplier.supplierId === supplier.supplierId,
          )
        ) {
          uniqueSuppliers.push(supplier);
        }
      }
    }

    return uniqueSuppliers;
  }

  function areArraysEqual<T>(a: T[], b: T[]) {
    if (a.length !== b.length) {
      return false;
    }

    return JSON.stringify(a) === JSON.stringify(b);
  }

  // change items state if one of the items has new quantity list
  const handleQuantityChange = (changedItemIndex: number) => {
    itemsState[changedItemIndex].priceInfo =
      getValues().items[changedItemIndex].priceInfo;
    setItemsState(itemsState);
  };

  // filter out selected suppliers
  const handleItemSupplierChange = () => {
    const { items } = getValues();

    const uniqueSelectedSuppliers = getUniqueSelectedSuppliers(items);

    const haveSelectedSuppliersChanged = !areArraysEqual(
      uniqueSelectedSuppliers,
      selectedSuppliers,
    );

    if (haveSelectedSuppliersChanged) {
      setSelectedSuppliers(getUniqueSelectedSuppliers(items));
    }

    if (
      typeof onSuppliersChange === "function" &&
      haveSelectedSuppliersChanged
    ) {
      onSuppliersChange(uniqueSelectedSuppliers);
    }
  };

  // trigger supplier change event for initial render
  useEffect(() => {
    const formValues = getValues().items;
    const uniqueSuppliers = getUniqueSelectedSuppliers(formValues || []);

    if (typeof onSuppliersChange === "function") {
      onSuppliersChange(uniqueSuppliers);
    }
  }, []);

  // setup removing rfx item mutation
  const [removePurchaseRequestItem] = useRemovePurchaseRequestItem({
    refetchQueries: ["PaginatedRfx", "RfxByCode"],
    awaitRefetchQueries: true,
  });

  const onDelete = async (itemId: string) => {
    // submit current form first
    if (typeof triggerSubmit === "function") {
      await triggerSubmit();
    }

    // display loader on delete button
    setDeletingItem({ itemId });
    await removePurchaseRequestItem({
      variables: { purchaseRequestItemId: itemId },
    });
    setDeletingItem(null);

    // reload (TODO: how to reset the form as react-hook-form reset API did not work correctly for this)
    window.location.reload();
  };

  const handleOnDuplicate = (itemIndex: number, questionIndex: number) => {
    const { items } = getValues();
    const formItems: Partial<Unbox<SourcingEventTemplate["items"]>> =
      items[itemIndex];
    const question = formItems.itemQuestions
      ? formItems.itemQuestions[questionIndex].question
      : "";

    appendRef.current.map((ref) => {
      if (canDuplicate(ref, itemIndex)) {
        ref.current.append({ question }, false);
      }
    });
  };

  return (
    <>
      <table
        data-state={isReadOnly ? "READONLY" : "EDITABLE"}
        className={classNames(styles["table"], className)}
      >
        {displayHeader && (
          <thead>
            <tr>
              <th></th>
              {!isReadOnly && (
                <>
                  <th>Unit price {baseCurrency}</th>
                  <th></th>
                </>
              )}
            </tr>
          </thead>
        )}
        <tbody>
          {itemsState?.map((item, itemIndex) => {
            return (
              <tr key={item.id}>
                <td className={styles["item-info"]}>
                  <input
                    type="hidden"
                    name={`items[${itemIndex}].id`}
                    value={item.id}
                    ref={register()}
                  />
                  <div className={styles["item-info-icon"]}>
                    {item.itemData?.type ===
                    PurchaseRequestItemTypeEnum.PRODUCT ? (
                      <ProductIcon />
                    ) : (
                      <ServiceIcon />
                    )}
                  </div>
                  <div className={styles["item-info-fields"]}>
                    <div className={styles["field-title"]}>
                      {item.itemData?.name}
                    </div>
                    {item.itemData?.itemAdditionalInfo && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <EditIcon />
                        </div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.itemAdditionalInfo}
                        </div>
                      </div>
                    )}
                    {item.itemData?.expectedDelivery && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <CalendarIcon />
                        </div>
                        <div
                          className={styles["field-item"]}
                        >{`Exp delivery: ${formatDate(
                          item.itemData?.expectedDelivery,
                        )}`}</div>
                      </div>
                    )}
                    {item.itemData?.url && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <LinkIcon />
                        </div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.url}
                        </div>
                      </div>
                    )}
                    {item.itemData?.ownCode && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>OC</div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.ownCode}
                        </div>
                      </div>
                    )}
                    {item.itemData?.suggestedSupplier && (
                      <div className={styles["field-item"]}>
                        <SupplierIcon className={styles["field-item-icon"]} />{" "}
                        {item.itemData.suggestedSupplier}
                      </div>
                    )}
                    {!isEmpty(item.itemData?.attachments) && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <AttachmentIcon />
                        </div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.attachments?.map(
                            (attachment: any) => (
                              <UploadedAttachment
                                key={attachment.id}
                                attachment={{
                                  id: attachment.id,
                                  filename: attachment.title,
                                  url: attachment.url,
                                }}
                                secondary
                              />
                            ),
                          )}
                        </div>
                      </div>
                    )}
                    {item.itemData?.supplierCode.value && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>SC</div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.supplierCode.value}
                        </div>
                      </div>
                    )}
                    {item.customFields?.map((customField, index) => {
                      return (
                        <div key={index} className={styles["field-item"]}>
                          <div className={styles["field-item-icon"]}>{`CF${
                            index + 1
                          }`}</div>
                          <div className={styles["field-item-info"]}>
                            <div className={styles["custom-field"]}>
                              <div className={styles["custom-field-label"]}>
                                {customField.label}
                              </div>
                              <div className={styles["custom-field-value"]}>
                                {customField.value}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {!isReadOnly && (
                      <QuestionList
                        ref={appendRef.current[itemIndex]}
                        className={styles["item-question-list"]}
                        itemIndex={itemIndex}
                        onDuplicate={handleOnDuplicate}
                      />
                    )}
                  </div>
                  <div className={styles["item-info-quantities"]}>
                    <QuantityList
                      className={styles["quantity"]}
                      itemIndex={itemIndex}
                      unit={item.itemData?.unit}
                      onChange={handleQuantityChange}
                    />
                  </div>
                </td>
                {!isReadOnly && (
                  <>
                    <td className={styles["quotation"]}>
                      {item.priceInfo?.map((p, index) => (
                        <DummyInputPlaceholder
                          className={styles["dummy-input"]}
                          key={index}
                        />
                      ))}
                    </td>
                    <td className={styles["actions"]}>
                      {rfx.suppliers.length > 1 && (
                        <SupplierSelectDropdown
                          rfx={rfx}
                          itemIndex={itemIndex}
                          onChange={handleItemSupplierChange}
                        />
                      )}
                      <Button
                        icon={<RemoveIcon className={styles["button-icon"]} />}
                        loading={deletingItem?.itemId === item.id}
                        onClick={() => {
                          if (onDelete && item.id) {
                            onDelete(item.id);
                          }
                        }}
                      />
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
